@mixin typography($config) {
  font-size: map_get($config, 'font-size');
  font-family: map_get($config, 'font-family');
}

@mixin spacing($config) {
  margin: map_get($config, 'margin');
  height: map_get($config, 'height');

  border-radius: map_get($config, 'border-radius');
}

@mixin field($styles) {
  font-family: map_get($styles, 'font-family');
  padding: map_get($styles, 'padding');
  margin-bottom: map_get($styles, 'margin-bottom');
}

@mixin button($primary, $background) {
  border-color: $primary;
  border-style: solid;
  color: $primary;
  background-color: $background;

  &:disabled {
    background-color: $background;
    opacity: 50%;
    cursor: default;
  }
}
