// Colors
$color-blue-0: #f4f7ff;
$color-blue-50: #eaefff;
$color-blue-100: #c2d1fc;
$color-blue-200: #91aef7;
$color-blue-300: #618bf1;
$color-blue-400: #3169ea;
$color-blue-500: #0047e0;
$color-blue-600: #003cc5;
$color-blue-700: #0031a8;
$color-blue-800: #00268a;
$color-blue-900: #001242;

$color-green-0: #fbfdfb;
$color-green-50: #d8efdd;
$color-green-100: #b5e1bf;
$color-green-200: #91d2a0;
$color-green-300: #6ec482;
$color-green-400: #4bb563;
$color-green-500: #28a745;
$color-green-600: #1e8c38;
$color-green-700: #16712b;
$color-green-800: #0f541e;
$color-green-900: #083613;

$color-red-0: #fefbfa;
$color-red-50: #f3ded3;
$color-red-100: #e9c0ab;
$color-red-200: #dea283;
$color-red-300: #d3845b;
$color-red-400: #c96634;
$color-red-500: #be480c;
$color-red-600: #a03a06;
$color-red-700: #812d02;
$color-red-800: #612000;
$color-red-900: #3e1400;

$color-yellow-0: #fffdfa;
$color-yellow-50: #fbeed5;
$color-yellow-100: #f7deaf;
$color-yellow-200: #f4cf8a;
$color-yellow-300: #f0bf64;
$color-yellow-400: #edb03f;
$color-yellow-500: #e9a019;
$color-yellow-600: #c58510;
$color-yellow-700: #9e6a0a;
$color-yellow-800: #764e05;
$color-yellow-900: #4c3201;

$color-gray-0: #fafbfc;
$color-gray-50: #f4f5f7;
$color-gray-100: #ebecf0;
$color-gray-200: #dfe1e6;
$color-gray-300: #b6bdc9;
$color-gray-400: #5e6e88;
$color-gray-500: #42526e;
$color-gray-600: #344563;
$color-gray-700: #253858;
$color-gray-800: #172b4d;
$color-gray-900: #091e42;

$white: #ffffff;
$brazen-aqua: #006eb3;
$hunted-canary: #ff9c00;
$aged-sky: #0071b3;
$prevalent-satin: #bebebe;
$preparatory-licorice: #333333;
$deep-tawny: #979797;
$concluding-pearl: #e5f5d7;
$sovereign-olive: #3c8127;
$freezing-pearl: #eef0f3;
$stuffy-steel: #32325d;
$cardiovascular-jet: #2d2d2d;
$tacky-marble: #fff8f8;
$weathered-ruby: #db3a1b;
$permanent-geranium-lake: #e92c2c;
$black: #000000;
$tarnished-oyster: #f4f4f4;
$poignant-platinum: #dedede;
$text-dimmed: #868686;
$text-subdued: #4f4f4f;
$grey-light: #f4f4f4;
$grey-bg: #ececec;
$white-smoke: #f6f6f6;
$midnight-express: #141d42;
$gray-90: #e5e5e5;
$green-cyan: #007b3a;
$silver: #b9b9b9;
$dim-gray: #666666;
$gray-85: #d9d9d9;
$orange-90: #bf2e00;
$mine-shaft: #333333;
$lynx-white: #f7f7f7;
$unicorn-silver: #e8e8e8;
$light-orange: #f98600;
$paid-green: #00ba34;
$matterhorn: #585757;
$bronze-metallic: #a29060;
$raisin-black: #212121;
$selago: #ebefff;

// Fonts
$font-size-0: 0.625rem;
$font-size-1: 0.75rem;
$font-size-2: 0.8125rem;
$font-size-3: 0.875rem;
$font-size-4: 1rem;
$font-size-5: 1.125rem;
$font-size-6: 1.25rem;
$font-size-7: 1.5rem;
$font-size-8: 1.75rem;
$font-size-9: 2rem;
$font-size-10: 2.5rem;
$font-size-11: 3rem;

// Borders
$default-border: 1px solid $color-gray-200;
$base-border-radius: 4px;
