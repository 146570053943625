@import 'themes/shared/shared-theme';
@import 'main';
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&family=Ubuntu:wght@300;400;500&display=swap');

$font-family-arial: Arial, sans-serif;
$font-family-base: 'Source Sans Pro', $font-family-arial;

$default-font: $font-family-base;

html {
  height: 100%;
}

body {
  background-color: $white;
  height: 100%;
  margin: 0;
}

button {
  font-size: $font-size-4;
  font-family: $default-font;
}

.logo {
  height: 64px;
  margin: 0;
}

.bva-theme {
  @include channel-theme(default-brand($default-font, $font-size-4));
}

.troostwijk-theme {
  @include channel-theme(troostwijk-brand());
}

.form-group {
  position: relative;
  padding: $space-default;
  background-color: $white;
  z-index: 1;
  border-radius: 6px;
}

.form-control {
  position: absolute;
  top: -2px;
  left: 0;
  right: 0;
  width: stretch;
  width: -webkit-fill-available;
  width: -moz-available;
  background-color: transparent;
  height: 100%;
  padding-left: $space-default;
  border: $default-border;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.04);
  border-radius: 6px;

  &.ng-invalid.ng-touched,
  &.ng-invalid.ng-dirty {
    border: 1px solid $orange-90;
  }
}

.form-control-placeholder {
  position: relative;
  display: flex;
  height: 100%;
  align-items: center;
  transition: all 300ms;
  font-size: $font-size-4;
  z-index: -1;
  color: $text-dimmed;
  margin: 0 !important;
}

.form-control:focus + .form-control-placeholder,
.form-control:not(:placeholder-shown) + .form-control-placeholder {
  font-size: 70%;
  bottom: $space-default;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}

.form-control::placeholder {
  color: transparent;
}

input {
  color: $text-subdued;
}

.d-none {
  display: none !important;
}

.d-flex {
  display: flex !important;
}

.pos-relative {
  position: relative;
}

.readonly {
  background-color: $grey-bg;
  outline: none;
  cursor: none;
  pointer-events: none;
}

.flex-grow-1 {
  flex-grow: 1;
}

.justify-content-center {
  justify-content: center;
}

.align-items-center {
  align-items: center;
}

.cursor-pointer {
  cursor: pointer;
}
