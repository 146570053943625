@import 'theming';
@import 'layout';
@import 'utils';
@import 'variables';

@mixin channel-theme($theme) {
  $button-theme: map_get($theme, 'button');
  $icon-theme: map_get($theme, 'icon');

  p,
  label {
    @include typography(map_get($theme, 'paragraph'));
    @include spacing(map_get($theme, 'paragraph'));
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include typography(map_get($theme, 'h'));
    @include spacing(map_get($theme, 'h'));
  }

  .action {
    @include typography($button-theme);
    @include spacing($button-theme);

    flex-grow: 1;
    outline: none;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  .action[disabled] {
    background-color: $prevalent-satin;
  }

  .action-primary {
    $primary: map_get($button-theme, 'primary');
    $background: map_get($button-theme, 'primary-background');

    @include button($primary, $background);
  }

  .action-secondary {
    $primary: map_get($button-theme, 'secondary');
    $background: map_get($button-theme, 'secondary-background');

    @include button($primary, $background);
  }

  .action-link {
    border: none;
    background: transparent;
    color: map_get($button-theme, 'secondary');
    cursor: pointer;
    font-weight: 500;
    text-align: right;

    &:hover {
      color: map_get($button-theme, 'primary-background');
    }

    &:disabled {
      cursor: default;
      opacity: 0.2;
    }
  }

  a.action-link {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .arrow {
    border: solid map_get($button-theme, 'primary-background');
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg);
  }

  .field {
    p {
      @include typography(map_get($theme, 'paragraph'));
      @include spacing(map_get($theme, 'paragraph'));
    }

    a {
      @include typography(map_get($theme, 'paragraph'));
      @include spacing(map_get($theme, 'paragraph'));
    }
  }

  .icon {
    color: map_get($icon-theme, 'color');
  }

  .heading {
    @include typography(map_get($theme, 'heading'));
    @include spacing(map_get($theme, 'heading'));
  }

  .form-control {
    $input-theme: map_get($theme, 'input');

    @include typography($input-theme);
    color: map_get($input-theme, 'color');

    &:focus {
      outline-color: map_get($input-theme, 'active-outline-color');
    }
  }
}
