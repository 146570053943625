.cdk-overlay-container {
  .cdk-overlay-backdrop-showing {
    &.dark-modal-backdrop {
      background: $mine-shaft;
      opacity: 0.5;
    }
  }

  .cdk-overlay-pane {
    justify-content: center;
  }

  .tba-modal {
    background: $white;
    border-radius: $base-border-radius;
    width: inherit;

    .tba-modal {
      background: $white;
      border-radius: $base-border-radius;
      width: inherit;

      @include e('header') {
        position: relative;
        padding: $space-m;
        border-bottom: 1px solid $color-gray-200;
        height: auto;

        @include e('close') {
          position: absolute;
          top: $space-xs;
          right: $space-xs;
          padding: $space-xxs;
          font-size: $icon-size-default;

          border-radius: $base-border-radius;

          display: flex;
          cursor: pointer;

          &:hover {
            background-color: $color-gray-100;
          }

          &:not([href]):not([tabindex]) {
            color: $text-dimmed;
          }
        }

        @include e('title') {
          margin: 0;
          padding-right: $space-xxl;
          font-size: $font-size-4;
          color: $text-subdued;
        }

        @include m('with-subtitle') {
          height: auto;
          overflow: auto;
        }

        @include m('without-margins') {
          margin: 0px;
        }
      }

      @include e('content') {
        padding: $space-m;

        .form {
          margin-bottom: 0;
        }

        @include m('no-content-padding') {
          padding: 0;
        }
      }
    }
  }
}
