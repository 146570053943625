@import 'themes/shared/variables';

$light-gray: #f9f9f9;

$h1-font-size: $font-size-6;
$h2-font-size: $font-size-5;

$weight-light: 300;
$weight-normal: 400;
$weight-medium: 500;
$weight-semibold: 600;
$weight-bold: 700;

$default-line-height: 150%;

// Breakpoints

// Small screen / phone
$grid-sm-min-width: 576px;
// Medium screen / tablet
$grid-md-min-width: 768px;
// Large screen / desktop
$grid-lg-min-width: 992px;
// Extra large screen / wide desktop
$grid-xl-min-width: 1200px;
