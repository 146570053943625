$space-default: 1rem; // 16px
$space-xxxs: 0.125rem; // 2px
$space-xxs: 0.25rem; // 4px
$space-xs: 0.5rem; // 8px
$space-s: 0.75rem; // 12px
$space-m: 1rem; // 16px
$space-l: 1.25rem; // 20px
$space-xl: 1.5rem; // 24px
$space-xxl: 2rem; // 32px
$space-xxxl: 3rem; // 48px
$space-xxxxl: 3.5rem; // 56px

// Icon sizes
$icon-size-xs: $space-s; // 12px
$icon-size-s: $space-m; // 16px
$icon-size-default: $space-l; // 20px
$icon-size-l: $space-xl; // 24px
$icon-size-xl: $space-xxl; // 32px
$icon-size-xxl: $space-xxxl; // 48px
$icon-size-xxxl: $space-xxxxl; // 56px
