.cdk-overlay-container {
  .tba-modal {
    .tba-modal {
      @include e('header') {
        padding: $space-s;
        height: auto;
        display: flex;
        justify-content: center;

        @include e('title') {
          padding-right: 0;
          font-size: $font-size-6;
          font-weight: $weight-semibold;
        }
      }
      @include e('content') {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
