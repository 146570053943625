@use 'variables';

.shimmer {
  $mask: linear-gradient(-60deg, #000 30%, #0005, #000 70%) right/300% 100%;
  mask: $mask;
  -webkit-mask: $mask;
  background-repeat: no-repeat;
  animation: shimmer 1s infinite;
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

.loading {
  .placeholder {
    @extend .shimmer;
    height: 14px;
    color: transparent !important;
    display: block;
    background: variables.$color-gray-100;
    width: 140px;

    &-dark {
      @extend .placeholder;
      background: variables.$color-gray-200;
    }
  }
}
