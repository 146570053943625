body {
  color: $text-subdued;
  font-size: $font-size-4;
  font-weight: $weight-normal;
  line-height: $default-line-height;
}

h1 {
  font-weight: $weight-semibold;
  line-height: $default-line-height;
  font-size: $h1-font-size;
  margin-bottom: 4px;
  color: $black;
}

h2 {
  font-weight: $weight-semibold;
  line-height: $default-line-height;
  font-size: $h2-font-size;
  margin-bottom: 4px;
  color: $black;
}
