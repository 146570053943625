.manage-roles-expanded {
  .toggle-switch input {
    &:checked + .slider {
      background-color: #008540 !important;
    }

    &:focus + .slider {
      box-shadow: 0 0 2px #008540 !important;
    }
  }
}
