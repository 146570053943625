$width: 360px;
$padding: 50px;

$columns: 12;

.container {
  display: flex;
  flex-wrap: wrap;
  margin: 0 auto;
  max-width: $width;
  padding: $padding;
}

@for $width from 1 to $columns + 1 {
  .col-#{$width} {
    flex-basis: $width / $columns * 100%;
  }
}
