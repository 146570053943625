@use 'themes/shared/variables' as sharedVariables;
@use 'variables';
@use 'themes/shared/spacing';

.full-col {
  margin-bottom: spacing.$space-default;
}

.two-col,
.three-col,
.two-halves {
  display: block;

  .col {
    margin-bottom: spacing.$space-default;
  }
}

@media (min-width: variables.$grid-sm-min-width) {
  .three-col {
    display: grid;
    grid-gap: spacing.$space-default;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    margin-bottom: spacing.$space-default;

    .col {
      margin-bottom: 0;
    }
  }

  .two-col {
    display: grid;
    grid-gap: spacing.$space-default;
    grid-template-columns: repeat(3, 1fr);
    margin-bottom: spacing.$space-default;

    .col {
      margin-bottom: 0;
    }

    .col-2-4 {
      grid-column: 2/4;
    }
  }

  .two-halves {
    display: grid;
    grid-gap: spacing.$space-default;
    grid-template-columns: repeat(2, 1fr);
    grid-column: 2/4;

    .col {
      margin-bottom: 0;
    }
  }
}
